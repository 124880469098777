<template>
  <div class="box">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="240px" class="ruleForm" size="small">
      <el-form-item label="标题" prop="title">
        <el-input v-model="info.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="订单运费">
        <el-input v-model="info.order_fee" placeholder="输入运费"></el-input>
      </el-form-item>
      <el-form-item label="一级佣金比例">
        <el-input v-model="info.commission_first" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="二级佣金比例">
        <el-input v-model="info.commission_second" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="分会长 -> 团员成交百分比">
        <el-input v-model="info.team_leader_employer" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="分会长 -> 一级会员成交百分比">
        <el-input v-model="info.team_leader_member" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="分会长 -> 区域成交百分比">
        <el-input v-model="info.team_leader_city" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="专员 -> 50单团员分佣比例">
        <el-input v-model="info.team_one_employer" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="专员 -> 50单一级会员分佣比例">
        <el-input v-model="info.team_one_member" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="专员 -> 50-100单团员分佣比例">
        <el-input v-model="info.team_two_employer" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="专员 -> 50-100单一级会员分佣比例">
        <el-input v-model="info.team_two_member" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="专员 -> 100以上单团员分佣比例">
        <el-input v-model="info.team_three_employer" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="专员 -> 100以上单一级会员分佣比例">
        <el-input v-model="info.team_three_member" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="销售 -> 分成比例">
        <el-input v-model="info.team_sale_lv" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="提现手续费">
        <el-input v-model="info.withdraw_lv" placeholder="输入比例">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="最低提现金额">
        <el-input v-model="info.withdraw_mini" placeholder="输入金额">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="兑换规则">
        <wang-edit :txt="info.exchange_rule" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item label="退货省市区" prop="return_address_city">
        <el-input v-model="info.return_address_city" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="退货详细地址" prop="return_address_address">
        <el-input v-model="info.return_address_address" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="退货收件人" prop="return_address_name">
        <el-input v-model="info.return_address_name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="退货收件电话" prop="return_address_mobile">
        <el-input v-model="info.return_address_mobile" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="书单需要购买会员id，英文逗号分隔" prop="test_book">
        <el-input v-model="info.test_book" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="书单未购买会员提示语" prop="test_book_tip">
        <el-input v-model="info.test_book_tip" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="书单押金" prop="book_deposit">
        <el-input v-model="info.book_deposit" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="视频需要购买会员id，英文逗号分隔" prop="video_vip">
        <el-input v-model="info.video_vip" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="视频vip未购买会员提示语" prop="video_vip_tips">
        <el-input v-model="info.video_vip_tips" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import WangEdit from "@/components/WangEdit";
  export default {
    data() {
      return {
        info: {
          title: '',
          order_fee: '',
          commission_first: '',
          commission_second: '',
          withdraw_lv: '',
          withdraw_mini: '',
          exchange_rule: '',
          return_address_city: '',
          return_address_address: '',
          return_address_name: '',
          return_address_mobile: '',
          team_leader_employer: '',
          team_leader_member: '',
          team_leader_city: '',
          team_one_employer: '',
          team_one_member: '',
          team_two_employer: '',
          team_two_member: '',
          team_three_employer: '',
          team_three_member: '',
          team_sale_lv: ''
        },
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' }
          ]
        }
      };
    },
    created() {
      this.getInfo()
    },
    mounted() {
    },
    components: {
      WangEdit
    },
    computed: {
    },
    methods: {
      setText(txt) {
        this.info.exchange_rule = txt
      },
      getInfo() {
        var that = this
        this.$api.merchant.sysDetail( function (res) {
          if(res.errcode == 0) {
            that.info = res.data
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = that.info
            this.$api.merchant.sysEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
</style>
